import Loading from 'ui/elements/Loading';
import Resource from 'util/resource/Resource';
import React, { useEffect } from 'react';
import CenteredModalDialog from 'ui/views/dialogs/CenteredModalDialog';
import Profile from 'domain/companies/ProfileDialog/Profile';
import { CompanyProfile } from 'types/company';
import { IResource } from 'util/resource';
import { useMediaQuery } from '@mui/material';
import { bluePlanetTheme } from 'ui/theme';
import { useHistory } from 'react-router-dom';
import { CompanyOverviewSection } from 'urls';
import { useCompanyProfileById } from 'apis/CompanyAPI/companies/useCompanyProfile';
import MuiFullscreenDialog from 'ui/views/dialogs/FullscreenDialog';
import useRoute from 'hooks/useRoute';

type Props = {
  onClose: () => void;
  renderSidebar?: (companyProfile: CompanyProfile) => React.ReactNode;
  renderActionBar?: React.ReactNode;
  modalId?: string;
  companyId: number;
  companyProfile?: IResource<CompanyProfile>;
  subRoutingIsEnabled?: boolean;
  initialTab?: CompanyOverviewSection;
};

export default function ProfileDialog(props: Props) {
  const { companyProfile, subRoutingIsEnabled } = props;
  const disableSubRouting = subRoutingIsEnabled !== true;

  const { resource: companyProfileResource } = useCompanyProfileById(!!companyProfile ? undefined : props.companyId);

  const sidebar = (profile: CompanyProfile): React.ReactNode =>
    props.renderSidebar ? props.renderSidebar(profile) : null;

  const isMobile = useMediaQuery(bluePlanetTheme.breakpoints.down('sm'));

  const history = useHistory();
  const { match } = useRoute();

  // This is an epic trick I invented to have the back button close the modal instead of going back in history
  // What could possibly go wrong? 😎 // severin
  useEffect(() => {
    if (disableSubRouting) {
      history.push(match.url);
    }
    const closeInsteadOfBack = history.listen((_, action) => {
      if (action === 'POP' && disableSubRouting) {
        props.onClose();
      }
    });
    return () => {
      closeInsteadOfBack();
    };
  }, [history]);

  // When the modal is closed the normal way we want to remove the extra item we added to history above
  const onClose = () => {
    props.onClose();
    if (disableSubRouting) {
      history.goBack();
    }
  };

  if (isMobile) {
    return (
      <MuiFullscreenDialog open onClose={onClose}>
        <Resource
          resource={companyProfile || companyProfileResource}
          renderLoading={() => (
            <div className="u-flex u-flex-center u-flex-align-center" style={{ minHeight: '20rem', height: '100%' }}>
              <Loading />
            </div>
          )}
        >
          {companyProfile => (
            <div className="u-section-spacing-bottom">
              <Profile
                companyProfile={companyProfile}
                onClose={onClose}
                renderSidebar={sidebar(companyProfile)}
                disableSubRouting={disableSubRouting}
                initialTab={props.initialTab}
              />
            </div>
          )}
        </Resource>
      </MuiFullscreenDialog>
    );
  }

  return (
    <>
      {/* DisableEnforceFocus must be set in order to make calendly dialog work properly */}
      <CenteredModalDialog
        open
        onClose={onClose}
        dialogActions={props.renderActionBar}
        modalId={props.modalId}
        disableEnforceFocus
        overflowY="scroll" // always show scrollbar to prevent window from jumping
        width="wide"
      >
        <Resource
          resource={companyProfile || companyProfileResource}
          renderLoading={() => (
            <div className="u-flex u-flex-center u-flex-align-center" style={{ minHeight: '20rem', height: '100%' }}>
              <Loading />
            </div>
          )}
        >
          {companyProfile => (
            <div className="u-section-spacing-bottom">
              <Profile
                companyProfile={companyProfile}
                onClose={onClose}
                renderSidebar={sidebar(companyProfile)}
                disableSubRouting={disableSubRouting}
                initialTab={props.initialTab}
              />
            </div>
          )}
        </Resource>
      </CenteredModalDialog>
    </>
  );
}
