import React, { useEffect, useMemo, useState } from 'react';
import { DocumentDTO } from 'types/documents';
import { Document, pdfjs } from 'react-pdf';
import { ICompany } from 'types/company';
import { CompanyViewAs } from 'domain/companies/roleUtils';
import { companiesApi } from 'apis/CompanyAPI/companies/companiesApi';
import usePitchDeckViewLogger from 'hooks/usePitchDeckViewLogger';
import useCompanyRole from 'hooks/useCompanyRole';
import Loading from 'ui/elements/Loading';
import PitchDeckPDFDialog from './PitchDeckPDFDialog';
import PitchDeckPDFPreview from './PitchDeckPDFPreview';
import { useKeyboardNavigation } from '../../../../../contexts/KeyboardNavigationContext';

pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.js', import.meta.url).toString();

const keyboardNavigationContextID = 'PitchDeckPDFViewer';

interface Props {
  document: DocumentDTO;
  company: ICompany;
  viewAs: CompanyViewAs;
  pitchDeckTitle?: string;
}

function PitchDeckPDFContainer(props: Props) {
  const [pitchDeckPath, setPitchDeckPath] = useState('');
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [numPages, setNumPages] = useState<number>(1);
  const [dialogPageNumber, setDialogPageNumber] = useState<number | null>(null);

  const containerRef = React.useRef<HTMLDivElement>(null);

  const { navigationContext, setNavigationContext } = useKeyboardNavigation();

  const access = useCompanyRole(props.company.slug);

  const logger = usePitchDeckViewLogger(props.company.id, props.document.id, access.canEditCompany);

  useEffect(() => {
    logger(pageNumber);
  }, [pageNumber]);

  function goToNextPage() {
    setPageNumber(prevPageNumber => Math.min(prevPageNumber + 1, numPages));
  }

  function goToPreviousPage() {
    setPageNumber(prevPageNumber => Math.max(prevPageNumber - 1, 1));
  }

  function goToPage(num: number) {
    setPageNumber(num);
  }

  const handleDownload = () => {
    companiesApi.profile.pitchDocuments
      .download(props.company.id, props.document.id, 'preview')
      .then(response => setPitchDeckPath(response.path));
  };

  const handleKeyboardNavigation = (event: KeyboardEvent) => {
    if (navigationContext !== keyboardNavigationContextID) return;
    if (event.key === 'ArrowRight') {
      goToNextPage();
    } else if (event.key === 'ArrowLeft') {
      goToPreviousPage();
    }
  };

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages);
  }

  /*useClickOutside(containerRef, () => {
    setNavigationContext(null);
  });*/

  useEffect(() => {
    window.addEventListener('keydown', handleKeyboardNavigation);
    return () => {
      window.removeEventListener('keydown', handleKeyboardNavigation);
    };
  }, [pageNumber, navigationContext]);

  useEffect(() => {
    handleDownload();
  }, [props.document.id]);

  const file = useMemo(() => pitchDeckPath, [pitchDeckPath]);

  return (
    <Document
      file={file}
      onLoadSuccess={onDocumentLoadSuccess}
      externalLinkTarget="_blank"
      loading={<Loading className="u-section-spacing-y" position="center" />}
      noData=""
    >
      <div ref={containerRef} onPointerDown={() => setNavigationContext(keyboardNavigationContextID)}>
        <PitchDeckPDFPreview
          renderingIsSuspended={!!dialogPageNumber}
          document={props.document}
          company={props.company}
          pitchDeckPath={pitchDeckPath}
          pageNumber={pageNumber}
          onThumbnailClick={clickedPage => setDialogPageNumber(clickedPage ?? pageNumber)}
          onNextPage={goToNextPage}
          onPreviousPage={goToPreviousPage}
          onPageChange={goToPage}
          viewAs={props.viewAs}
          pitchDeckTitle={props.pitchDeckTitle}
          numPages={numPages}
          setNumPages={setNumPages}
        />
      </div>
      {dialogPageNumber && (
        <PitchDeckPDFDialog
          numPages={numPages}
          onClose={() => setDialogPageNumber(null)}
          pageNumber={dialogPageNumber}
        />
      )}
    </Document>
  );
}

const areEqual = (prevProps: Props, nextProps: Props) => {
  return prevProps.company.id === nextProps.company.id;
};

export default React.memo(PitchDeckPDFContainer, areEqual);
