import React, { useEffect, useRef, useState, memo } from 'react';

import 'react-pdf/dist/Page/AnnotationLayer.css';
import { ListChildComponentProps, VariableSizeList } from 'react-window';
import { Page } from 'react-pdf';
import AutoSizer from 'react-virtualized-auto-sizer';

interface Props {
  pageNumber: number;
  numPages: number;
}

interface PdfPageProps {
  index: number;
  width: number;
  style: React.CSSProperties;
  updateItemSize: (index: number, height: number) => void;
}

const PdfPage = memo(({ index, width, style, updateItemSize }: PdfPageProps) => {
  return (
    <div
      key={`page_${index + 1}`}
      style={{
        ...style,
        transformOrigin: '0 0',
        overflow: 'hidden',
      }}
    >
      <Page
        key={`page_1_${index + 1}`}
        pageNumber={index + 1}
        renderAnnotationLayer={false}
        renderTextLayer={false}
        width={width}
        devicePixelRatio={Math.min(2, window.devicePixelRatio)}
        onRenderSuccess={page => {
          if (page.originalHeight && page.originalWidth) {
            const aspectRatio = page.originalHeight / page.originalWidth;
            const calculatedHeight = width * aspectRatio;
            updateItemSize(index, calculatedHeight);
          }
        }}
      />
    </div>
  );
});

export default function PitchDeckPDF({ pageNumber, numPages }: Props) {
  const listRef = useRef<VariableSizeList>(null);
  const [itemSize, setItemSize] = useState<number>(0);

  const updateItemSize = (index: number, height: number) => {
    setItemSize(height);
    listRef.current?.resetAfterIndex(0);
  };

  const [hasScrolledToPage, setHasScrolledToPage] = useState(false);

  useEffect(() => {
    if (!hasScrolledToPage && itemSize !== 0) {
      listRef.current?.scrollTo((pageNumber - 1) * itemSize + (pageNumber - 1) * 20);
      setHasScrolledToPage(true);
    }
  }, [itemSize]);

  return (
    <AutoSizer>
      {({ width, height }) => (
        <VariableSizeList
          ref={listRef}
          itemCount={numPages}
          width={width}
          height={height}
          overscanCount={5}
          itemSize={() => itemSize + 20}
          style={{
            touchAction: 'pan-y pinch-zoom',
            WebkitOverflowScrolling: 'touch',
          }}
        >
          {({ index, style }: ListChildComponentProps) => (
            <PdfPage index={index} width={width} style={style} updateItemSize={updateItemSize} />
          )}
        </VariableSizeList>
      )}
    </AutoSizer>
  );
}
